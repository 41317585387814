exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaign-complete-tsx": () => import("./../../../src/pages/campaign/complete.tsx" /* webpackChunkName: "component---src-pages-campaign-complete-tsx" */),
  "component---src-pages-campaign-construction-management-index-tsx": () => import("./../../../src/pages/campaign/construction-management/index.tsx" /* webpackChunkName: "component---src-pages-campaign-construction-management-index-tsx" */),
  "component---src-pages-campaign-pm-2022-05-tsx": () => import("./../../../src/pages/campaign/pm_2022_05.tsx" /* webpackChunkName: "component---src-pages-campaign-pm-2022-05-tsx" */),
  "component---src-pages-campaign-pm-tsx": () => import("./../../../src/pages/campaign/pm.tsx" /* webpackChunkName: "component---src-pages-campaign-pm-tsx" */),
  "component---src-pages-casestudy-tsx": () => import("./../../../src/pages/casestudy.tsx" /* webpackChunkName: "component---src-pages-casestudy-tsx" */),
  "component---src-pages-en-campaigns-project-management-index-tsx": () => import("./../../../src/pages/en/campaigns/project-management/index.tsx" /* webpackChunkName: "component---src-pages-en-campaigns-project-management-index-tsx" */),
  "component---src-pages-en-features-calendar-index-tsx": () => import("./../../../src/pages/en/features/calendar/index.tsx" /* webpackChunkName: "component---src-pages-en-features-calendar-index-tsx" */),
  "component---src-pages-en-features-chat-dailyreport-index-tsx": () => import("./../../../src/pages/en/features/chat-dailyreport/index.tsx" /* webpackChunkName: "component---src-pages-en-features-chat-dailyreport-index-tsx" */),
  "component---src-pages-en-features-customization-features-index-tsx": () => import("./../../../src/pages/en/features/customization-features/index.tsx" /* webpackChunkName: "component---src-pages-en-features-customization-features-index-tsx" */),
  "component---src-pages-en-features-digital-reports-index-tsx": () => import("./../../../src/pages/en/features/digital-reports/index.tsx" /* webpackChunkName: "component---src-pages-en-features-digital-reports-index-tsx" */),
  "component---src-pages-en-features-gantt-chart-index-tsx": () => import("./../../../src/pages/en/features/gantt-chart/index.tsx" /* webpackChunkName: "component---src-pages-en-features-gantt-chart-index-tsx" */),
  "component---src-pages-en-features-import-export-index-tsx": () => import("./../../../src/pages/en/features/import-export/index.tsx" /* webpackChunkName: "component---src-pages-en-features-import-export-index-tsx" */),
  "component---src-pages-en-features-index-tsx": () => import("./../../../src/pages/en/features/index.tsx" /* webpackChunkName: "component---src-pages-en-features-index-tsx" */),
  "component---src-pages-en-features-integrated-management-index-tsx": () => import("./../../../src/pages/en/features/integrated-management/index.tsx" /* webpackChunkName: "component---src-pages-en-features-integrated-management-index-tsx" */),
  "component---src-pages-en-features-onsite-management-index-tsx": () => import("./../../../src/pages/en/features/onsite-management/index.tsx" /* webpackChunkName: "component---src-pages-en-features-onsite-management-index-tsx" */),
  "component---src-pages-en-features-photo-and-document-index-tsx": () => import("./../../../src/pages/en/features/photo-and-document/index.tsx" /* webpackChunkName: "component---src-pages-en-features-photo-and-document-index-tsx" */),
  "component---src-pages-en-features-security-index-tsx": () => import("./../../../src/pages/en/features/security/index.tsx" /* webpackChunkName: "component---src-pages-en-features-security-index-tsx" */),
  "component---src-pages-en-features-work-report-index-tsx": () => import("./../../../src/pages/en/features/work-report/index.tsx" /* webpackChunkName: "component---src-pages-en-features-work-report-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-pricing-tsx": () => import("./../../../src/pages/en/pricing.tsx" /* webpackChunkName: "component---src-pages-en-pricing-tsx" */),
  "component---src-pages-feature-black-board-tsx": () => import("./../../../src/pages/feature/black-board.tsx" /* webpackChunkName: "component---src-pages-feature-black-board-tsx" */),
  "component---src-pages-feature-calendar-tsx": () => import("./../../../src/pages/feature/calendar.tsx" /* webpackChunkName: "component---src-pages-feature-calendar-tsx" */),
  "component---src-pages-feature-chat-dailyreport-tsx": () => import("./../../../src/pages/feature/chat-dailyreport.tsx" /* webpackChunkName: "component---src-pages-feature-chat-dailyreport-tsx" */),
  "component---src-pages-feature-customization-features-tsx": () => import("./../../../src/pages/feature/customization-features.tsx" /* webpackChunkName: "component---src-pages-feature-customization-features-tsx" */),
  "component---src-pages-feature-gantt-chart-tsx": () => import("./../../../src/pages/feature/gantt-chart.tsx" /* webpackChunkName: "component---src-pages-feature-gantt-chart-tsx" */),
  "component---src-pages-feature-gemba-report-tsx": () => import("./../../../src/pages/feature/gemba-report.tsx" /* webpackChunkName: "component---src-pages-feature-gemba-report-tsx" */),
  "component---src-pages-feature-import-export-tsx": () => import("./../../../src/pages/feature/import-export.tsx" /* webpackChunkName: "component---src-pages-feature-import-export-tsx" */),
  "component---src-pages-feature-index-tsx": () => import("./../../../src/pages/feature/index.tsx" /* webpackChunkName: "component---src-pages-feature-index-tsx" */),
  "component---src-pages-feature-integrated-management-tsx": () => import("./../../../src/pages/feature/integrated-management.tsx" /* webpackChunkName: "component---src-pages-feature-integrated-management-tsx" */),
  "component---src-pages-feature-onsite-management-tsx": () => import("./../../../src/pages/feature/onsite-management.tsx" /* webpackChunkName: "component---src-pages-feature-onsite-management-tsx" */),
  "component---src-pages-feature-photo-and-document-tsx": () => import("./../../../src/pages/feature/photo-and-document.tsx" /* webpackChunkName: "component---src-pages-feature-photo-and-document-tsx" */),
  "component---src-pages-feature-security-tsx": () => import("./../../../src/pages/feature/security.tsx" /* webpackChunkName: "component---src-pages-feature-security-tsx" */),
  "component---src-pages-feature-work-report-tsx": () => import("./../../../src/pages/feature/work-report.tsx" /* webpackChunkName: "component---src-pages-feature-work-report-tsx" */),
  "component---src-pages-id-features-calendar-index-tsx": () => import("./../../../src/pages/id/features/calendar/index.tsx" /* webpackChunkName: "component---src-pages-id-features-calendar-index-tsx" */),
  "component---src-pages-id-features-chat-dailyreport-index-tsx": () => import("./../../../src/pages/id/features/chat-dailyreport/index.tsx" /* webpackChunkName: "component---src-pages-id-features-chat-dailyreport-index-tsx" */),
  "component---src-pages-id-features-customization-features-index-tsx": () => import("./../../../src/pages/id/features/customization-features/index.tsx" /* webpackChunkName: "component---src-pages-id-features-customization-features-index-tsx" */),
  "component---src-pages-id-features-digital-reports-index-tsx": () => import("./../../../src/pages/id/features/digital-reports/index.tsx" /* webpackChunkName: "component---src-pages-id-features-digital-reports-index-tsx" */),
  "component---src-pages-id-features-gantt-chart-index-tsx": () => import("./../../../src/pages/id/features/gantt-chart/index.tsx" /* webpackChunkName: "component---src-pages-id-features-gantt-chart-index-tsx" */),
  "component---src-pages-id-features-import-export-index-tsx": () => import("./../../../src/pages/id/features/import-export/index.tsx" /* webpackChunkName: "component---src-pages-id-features-import-export-index-tsx" */),
  "component---src-pages-id-features-index-tsx": () => import("./../../../src/pages/id/features/index.tsx" /* webpackChunkName: "component---src-pages-id-features-index-tsx" */),
  "component---src-pages-id-features-integrated-management-index-tsx": () => import("./../../../src/pages/id/features/integrated-management/index.tsx" /* webpackChunkName: "component---src-pages-id-features-integrated-management-index-tsx" */),
  "component---src-pages-id-features-onsite-management-index-tsx": () => import("./../../../src/pages/id/features/onsite-management/index.tsx" /* webpackChunkName: "component---src-pages-id-features-onsite-management-index-tsx" */),
  "component---src-pages-id-features-photo-and-document-index-tsx": () => import("./../../../src/pages/id/features/photo-and-document/index.tsx" /* webpackChunkName: "component---src-pages-id-features-photo-and-document-index-tsx" */),
  "component---src-pages-id-features-security-index-tsx": () => import("./../../../src/pages/id/features/security/index.tsx" /* webpackChunkName: "component---src-pages-id-features-security-index-tsx" */),
  "component---src-pages-id-features-work-report-index-tsx": () => import("./../../../src/pages/id/features/work-report/index.tsx" /* webpackChunkName: "component---src-pages-id-features-work-report-index-tsx" */),
  "component---src-pages-id-index-tsx": () => import("./../../../src/pages/id/index.tsx" /* webpackChunkName: "component---src-pages-id-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-seminars-tsx": () => import("./../../../src/pages/seminars.tsx" /* webpackChunkName: "component---src-pages-seminars-tsx" */),
  "component---src-pages-th-campaigns-project-management-index-tsx": () => import("./../../../src/pages/th/campaigns/project-management/index.tsx" /* webpackChunkName: "component---src-pages-th-campaigns-project-management-index-tsx" */),
  "component---src-pages-th-features-calendar-index-tsx": () => import("./../../../src/pages/th/features/calendar/index.tsx" /* webpackChunkName: "component---src-pages-th-features-calendar-index-tsx" */),
  "component---src-pages-th-features-chat-dailyreport-index-tsx": () => import("./../../../src/pages/th/features/chat-dailyreport/index.tsx" /* webpackChunkName: "component---src-pages-th-features-chat-dailyreport-index-tsx" */),
  "component---src-pages-th-features-customization-features-index-tsx": () => import("./../../../src/pages/th/features/customization-features/index.tsx" /* webpackChunkName: "component---src-pages-th-features-customization-features-index-tsx" */),
  "component---src-pages-th-features-digital-reports-index-tsx": () => import("./../../../src/pages/th/features/digital-reports/index.tsx" /* webpackChunkName: "component---src-pages-th-features-digital-reports-index-tsx" */),
  "component---src-pages-th-features-gantt-chart-index-tsx": () => import("./../../../src/pages/th/features/gantt-chart/index.tsx" /* webpackChunkName: "component---src-pages-th-features-gantt-chart-index-tsx" */),
  "component---src-pages-th-features-import-export-index-tsx": () => import("./../../../src/pages/th/features/import-export/index.tsx" /* webpackChunkName: "component---src-pages-th-features-import-export-index-tsx" */),
  "component---src-pages-th-features-index-tsx": () => import("./../../../src/pages/th/features/index.tsx" /* webpackChunkName: "component---src-pages-th-features-index-tsx" */),
  "component---src-pages-th-features-integrated-management-index-tsx": () => import("./../../../src/pages/th/features/integrated-management/index.tsx" /* webpackChunkName: "component---src-pages-th-features-integrated-management-index-tsx" */),
  "component---src-pages-th-features-onsite-management-index-tsx": () => import("./../../../src/pages/th/features/onsite-management/index.tsx" /* webpackChunkName: "component---src-pages-th-features-onsite-management-index-tsx" */),
  "component---src-pages-th-features-photo-and-document-index-tsx": () => import("./../../../src/pages/th/features/photo-and-document/index.tsx" /* webpackChunkName: "component---src-pages-th-features-photo-and-document-index-tsx" */),
  "component---src-pages-th-features-security-index-tsx": () => import("./../../../src/pages/th/features/security/index.tsx" /* webpackChunkName: "component---src-pages-th-features-security-index-tsx" */),
  "component---src-pages-th-features-work-report-index-tsx": () => import("./../../../src/pages/th/features/work-report/index.tsx" /* webpackChunkName: "component---src-pages-th-features-work-report-index-tsx" */),
  "component---src-pages-th-index-tsx": () => import("./../../../src/pages/th/index.tsx" /* webpackChunkName: "component---src-pages-th-index-tsx" */),
  "component---src-ui-templates-case-study-detail-tsx": () => import("./../../../src/ui/templates/CaseStudyDetail.tsx" /* webpackChunkName: "component---src-ui-templates-case-study-detail-tsx" */),
  "component---src-ui-templates-news-detail-tsx": () => import("./../../../src/ui/templates/NewsDetail.tsx" /* webpackChunkName: "component---src-ui-templates-news-detail-tsx" */),
  "component---src-ui-templates-product-release-detail-tsx": () => import("./../../../src/ui/templates/ProductReleaseDetail.tsx" /* webpackChunkName: "component---src-ui-templates-product-release-detail-tsx" */),
  "component---src-ui-templates-product-releases-tsx": () => import("./../../../src/ui/templates/ProductReleases.tsx" /* webpackChunkName: "component---src-ui-templates-product-releases-tsx" */),
  "component---src-ui-templates-seminar-detail-tsx": () => import("./../../../src/ui/templates/SeminarDetail.tsx" /* webpackChunkName: "component---src-ui-templates-seminar-detail-tsx" */)
}

